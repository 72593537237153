import jQuery from "jquery";
import "slick-carousel";

// custom

jQuery(document).ready(function () {
    jQuery(".slider").slick({
        autoplay: false,
        autoplaySpeed: 1500,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false
    });

    jQuery(".slider-testimonial").slick({
        autoplay:false,
        autoplaySpeed:3500,
        arrows:true,
        slidesToShow:1,
        slidesToScroll:1
    });

    jQuery(".humburger-icon").on("click", function () {
        jQuery("body").toggleClass("expanded-icon");
    });

    jQuery("table").wrap("<div class='table-set-responsive'></div>");
});

jQuery(document).ready(function () {
    jQuery(".humburger-icon").on("click", function () {
        jQuery("body").toggleClass("expanded-icon");
    });

    jQuery("table").wrap("<div class='table-set-responsive'></div>");
});
